import '@src/scss/main.scss';
import Button from '@src/components/Button.tsx';
import Input from '@src/components/Input.tsx';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Label from '@src/components/Label.tsx';
import Divider from '@src/components/Divider.tsx';
import CheckBox from '@src/components/CheckBox.tsx';
import React, { useEffect, useState } from 'react';
import chevronRight from '@src/assets/icons/chevron_right_black.svg';
import Snackbar from '@src/components/SnackBar.tsx';
import {
  IApiError,
  IApiPOSTSuccess,
  IApiAuthSuccess,
} from '@src/util/interfaces.ts';
import { useMutation } from '@tanstack/react-query';
import {
  API_URL,
  API_URLS,
  EmailVerificationStatus,
} from '@src/util/constants.ts';
import Modal from '@src/components/Modal.tsx';
import { useNavigate } from 'react-router-dom';
import { passwordRegex, phoneRegex } from '@src/util/functions.ts';
import api from '@src/util/api.ts';
import Container from '@src/layouts/Container.tsx';
import DetailHeader from '@src/components/DetailHeader.tsx';
import InputLabel from '@src/components/InputLabel.tsx';
import {
  useEmailRegisterFormStatusStore,
  useEmailRegisterFormStore,
  useUserStore,
} from '@src/util/store.ts';
import Cookies from 'js-cookie';
import BottomButton from '@src/components/BottomButton.tsx';

interface RegisterEmailProps {}

export const ZEmail = z
  .string()
  .trim()
  .min(1, { message: '이메일을 입력해 주세요.' })
  .email({ message: '이메일 형태가 아닙니다.' });
export const ZEmailVerification = z.string();

export const EmailRegisterFormBase = z
  .object({
    password: z.string().regex(passwordRegex, '비밀번호가 유효하지 않습니다.'),
    password_confirm: z.string(),
    name: z.string().trim().min(1, { message: '이름을 입력해 주세요.' }),
    phone_number: z.string().regex(phoneRegex, '전화번호가 유효하지 않습니다.'),
  })
  .extend({
    email: ZEmail,
  });

export const EmailRegisterFormClient = EmailRegisterFormBase.extend({
  personalTerm: z.boolean(),
  serviceTerm: z.boolean(),
  marketingTerm: z.boolean(),
  termsAll: z.boolean(),
})
  .extend({
    emailVerification: ZEmailVerification,
  })
  .refine((data) => data.password === data.password_confirm, {
    message: '비밀번호가 일치하지 않습니다.',
    path: ['password_confirm'],
  });

export const EmailVerificationForm = z.object({
  email: ZEmail,
  value: ZEmailVerification,
});

export const EmailRequestForm = z.object({
  email: ZEmail,
});

export const EmailRegisterFormServer = EmailRegisterFormBase.extend({
  is_marketing: z.boolean(),
}).omit({
  password_confirm: true,
});

export type TEmailRegisterFormClient = z.infer<typeof EmailRegisterFormClient>;
export type TEmailRegisterFormServer = z.infer<typeof EmailRegisterFormServer>;
export type TEmailVerificationForm = z.infer<typeof EmailVerificationForm>;
export type TEmailRequestForm = z.infer<typeof EmailRequestForm>;

const RegisterEmail: React.FC<RegisterEmailProps> = () => {
  const [emailRegisterForm, setEmailRegisterForm] = useEmailRegisterFormStore(
    (state) => [state.emailRegisterForm, state.setEmailRegisterForm],
  );
  const [emailRegisterFormState, setEmailRegisterFormState] =
    useEmailRegisterFormStatusStore((state) => [
      state.emailRegisterFormState,
      state.setEmailRegisterFormState,
    ]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    control,
    setValue,
    getValues,
  } = useForm<TEmailRegisterFormClient>({
    resolver: zodResolver(EmailRegisterFormClient),
    mode: 'onChange',
    defaultValues: {
      email: emailRegisterForm !== null ? emailRegisterForm.email : '',
      password: emailRegisterForm !== null ? emailRegisterForm.password : '',
      password_confirm:
        emailRegisterForm !== null ? emailRegisterForm.password_confirm : '',
      name: emailRegisterForm !== null ? emailRegisterForm.name : '',
      phone_number:
        emailRegisterForm !== null ? emailRegisterForm.phone_number : '',
      emailVerification:
        emailRegisterForm !== null ? emailRegisterForm.emailVerification : '',
      personalTerm:
        emailRegisterForm !== null ? emailRegisterForm.personalTerm : false,
      serviceTerm:
        emailRegisterForm !== null ? emailRegisterForm.serviceTerm : false,
      marketingTerm:
        emailRegisterForm !== null ? emailRegisterForm.marketingTerm : false,
      termsAll: emailRegisterForm !== null ? emailRegisterForm.termsAll : false,
    },
  });

  const navigate = useNavigate();

  const [emailVerificationStatus, setEmailVerificationStatus] = useState(
    emailRegisterFormState !== null
      ? emailRegisterFormState
      : EmailVerificationStatus.INITIAL,
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [errorModal, setErrorModal] = useState('');

  const [setUser] = useUserStore((state) => [state.setUser]);

  const emailValue = watch('email');
  const emailVerificationValue = watch('emailVerification');
  const personalTermValue = watch('personalTerm');
  const serviceTermValue = watch('serviceTerm');
  const marketingTermValue = watch('marketingTerm');
  const termsAllValue = watch('termsAll');

  const { mutate, isPending: isLoading } = useMutation<
    IApiAuthSuccess,
    IApiError,
    TEmailRegisterFormServer
  >({
    mutationFn: (formData) => {
      return api.post(API_URL + '/v1/auth/user', formData);
    },
  });

  const { mutate: mutateRequest, isPending: requestLoading } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TEmailRequestForm
  >({
    mutationFn: (formData) => {
      return api.post(API_URLS.SEND_EMAIL, formData);
    },
  });

  const {
    mutate: mutateVerify,
    isPending: verifyLoading,
    isError: isVerifyError,
    error: verifyError,
  } = useMutation<IApiPOSTSuccess, IApiError, TEmailVerificationForm>({
    mutationFn: (formData) => {
      return api.post(API_URL + '/v1/auth/email/complete', {
        email: formData.email,
        value: formData.value,
      });
    },
  });

  const onSubmit: SubmitHandler<TEmailRegisterFormClient> = (data) => {
    setEmailRegisterForm({
      email: '',
      password: '',
      password_confirm: '',
      name: '',
      phone_number: '',
      emailVerification: '',
      personalTerm: false,
      serviceTerm: false,
      marketingTerm: false,
      termsAll: false,
    });
    setEmailRegisterFormState(null);

    const formData = {
      email: data.email,
      password: data.password,
      name: data.name,
      phone_number: data.phone_number.replace(/-/g, ''),
      is_marketing: marketingTermValue,
    };
    mutate(formData, {
      onSuccess: (response) => {
        console.log(response);
        const { accessToken, refreshToken, user } = response.data;

        user.selectedCompany = null;

        Cookies.set('accessToken', accessToken, { expires: 7 });
        Cookies.set('refreshToken', refreshToken, { expires: 7 });
        if (!user?.current_manager) {
          user.current_manager = undefined;
        }

        setUser(user);
        setModalOpen(true);
      },
      onError: (error) => {
        setErrorModal(error?.response?.data?.message);
      },
    });
  };

  useEffect(() => {
    if (serviceTermValue && marketingTermValue && personalTermValue) {
      setValue('termsAll', true);
    } else {
      setValue('termsAll', false);
    }
  }, [serviceTermValue, marketingTermValue, personalTermValue, setValue]);

  const verifyEmailRequest = () => {
    setEmailVerificationStatus(EmailVerificationStatus.REQUESTED);
    mutateRequest(
      {
        email: emailValue,
      },
      {
        onSuccess: (response) => {
          console.log(response);
          setSnackbarOpen(true);
        },
        onError: (error) => {
          setErrorModal(error?.response?.data?.message);
        },
        onSettled: () => {
          setTimeout(() => setSnackbarOpen(false), 3000);
        },
      },
    );
  };

  const verifyEmail = () => {
    mutateVerify(
      {
        email: emailValue,
        value: emailVerificationValue,
      },
      {
        onSuccess: (response) => {
          console.log(response);
          setEmailVerificationStatus(EmailVerificationStatus.VERIFIED);
        },
        onError: (error) => {
          setErrorModal(error?.response?.data?.message);
          console.log(error);
        },
      },
    );
  };

  const handleNavigateTerms = (url: string) => {
    setEmailRegisterForm(getValues());
    setEmailRegisterFormState(emailVerificationStatus);
    navigate(url);
  };

  return (
    <Container
      header={<DetailHeader title={'회원가입'} />}
      modal={
        <>
          <Modal
            isOpen={isModalOpen}
            onOk={() => {
              setModalOpen(false);
              navigate('/register/company', { replace: true });
            }}
            title={'회원 가입 완료'}
            content={'회원가입이 완료되었습니다.'}
            okButton={'확인'}
            onClose={() => {
              setModalOpen(false);
            }}
          />
          <Modal
            isOpen={errorModal != ''}
            onOk={() => {
              setErrorModal('');
            }}
            title={'ERROR'}
            content={errorModal}
            okButton={'확인'}
            onClose={() => {
              setErrorModal('');
            }}
          />
          <Snackbar
            message="이메일 인증이 요청되었습니다."
            open={snackbarOpen}
            type={'info'}
          />
        </>
      }
    >
      <form
        className={'register-email-container-wrapper'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="register-email-container">
          <div className="register-email-content">
            <div className="register-email-form">
              <div className="email-input-container">
                <div className="input-label-container">
                  <Label text="이메일" required={true} />

                  <div className="email-verify-container">
                    <Input
                      placeholder="이메일"
                      register={register('email')}
                      isLoading={false}
                      disabled={
                        emailVerificationStatus ===
                        EmailVerificationStatus.VERIFIED
                      }
                    />
                    <Button
                      onClick={() => {
                        verifyEmailRequest();
                      }}
                      text={emailVerificationStatus}
                      customClassName="button-secondary"
                      disabled={
                        requestLoading ||
                        !emailValue ||
                        !!errors.email ||
                        emailVerificationStatus ===
                          EmailVerificationStatus.VERIFIED
                      }
                      style={{
                        width: '96px',
                        alignSelf: 'stretch',
                        whiteSpace: 'nowrap',
                      }}
                    />
                  </div>
                </div>
                {emailVerificationStatus ===
                  EmailVerificationStatus.REQUESTED && (
                  <div className="email-verify-container">
                    <Input
                      placeholder="인증번호 입력"
                      register={register('emailVerification')}
                      isLoading={false}
                    />
                    <Button
                      onClick={() => {
                        verifyEmail();
                      }}
                      text={'인증번호 확인'}
                      customClassName="button-secondary"
                      disabled={
                        !emailVerificationValue || !!errors.emailVerification
                      }
                      type={'button'}
                      isLoading={verifyLoading}
                      style={{
                        width: '96px',
                        alignSelf: 'stretch',
                        whiteSpace: 'nowrap',
                      }}
                    />
                  </div>
                )}
                {isVerifyError && (
                  <div className="error-message">
                    {verifyError?.response.data.message}
                  </div>
                )}
              </div>
              <InputLabel
                text={'비밀번호'}
                required
                description={
                  '영문자(대,소문자), 숫자를 포함하여 최소 8자 이상 15자 이하로 작성 해야 합니다.'
                }
                error={
                  errors.password ? (
                    <p className={'error-message'}>{errors.password.message}</p>
                  ) : errors.password_confirm ? (
                    <p className={'error-message'}>
                      {errors.password_confirm.message}
                    </p>
                  ) : (
                    <></>
                  )
                }
              >
                <Input
                  placeholder="비밀번호"
                  register={register('password')}
                  isLoading={false}
                  type={'password'}
                />
                <Input
                  placeholder="비밀번호 확인"
                  register={register('password_confirm')}
                  isLoading={false}
                  type={'password'}
                />
              </InputLabel>
              <InputLabel text={'성명'} required>
                <Input
                  placeholder="성명"
                  register={register('name')}
                  isLoading={false}
                />
              </InputLabel>
              <InputLabel
                text={'휴대폰 번호'}
                required
                error={
                  errors.phone_number && (
                    <p className={'error-message'}>
                      {errors.phone_number.message}
                    </p>
                  )
                }
              >
                <Input
                  placeholder="휴대폰 번호"
                  register={register('phone_number')}
                  isLoading={false}
                  inputMode={'tel'}
                />
              </InputLabel>
              <div className="input-label-container">
                <Label text={'약관동의'} required={false} />
                <div className="terms-and-conditions">
                  <Controller
                    name={'termsAll'}
                    control={control}
                    render={({ field: { value } }) => (
                      <CheckBox
                        checked={value}
                        onChange={() => {
                          setValue('termsAll', !termsAllValue);
                          if (
                            personalTermValue &&
                            serviceTermValue &&
                            marketingTermValue
                          ) {
                            setValue('personalTerm', false);
                            setValue('serviceTerm', false);
                            setValue('marketingTerm', false);
                          } else {
                            setValue('personalTerm', true);
                            setValue('serviceTerm', true);
                            setValue('marketingTerm', true);
                          }
                        }}
                        label={'전체동의'}
                        size={'lg'}
                      />
                    )}
                  />
                  <Divider />
                  <div className="terms-and-conditions-detail-container">
                    <Controller
                      name={'serviceTerm'}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CheckBox
                          checked={value}
                          onChange={onChange}
                          label={'[필수] 서비스 이용약관'}
                          size={'lg'}
                        />
                      )}
                    />
                    <img
                      src={chevronRight}
                      className={'pointer'}
                      alt={'arrow-right'}
                      onClick={() => {
                        handleNavigateTerms('/terms/service');
                      }}
                    />
                  </div>
                  <div className="terms-and-conditions-detail-container">
                    <Controller
                      name={'personalTerm'}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CheckBox
                          checked={value}
                          onChange={onChange}
                          label={'[필수] 개인정보처리방침'}
                          size={'lg'}
                        />
                      )}
                    />
                    <img
                      src={chevronRight}
                      className={'pointer'}
                      alt={'arrow-right'}
                      onClick={() => {
                        handleNavigateTerms('/terms/personal');
                      }}
                    />
                  </div>

                  <div className="terms-and-conditions-detail-container">
                    <Controller
                      name={'marketingTerm'}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CheckBox
                          checked={value}
                          onChange={onChange}
                          label={'[선택] 마케팅 정보 수신 동의'}
                          size={'lg'}
                        />
                      )}
                    />
                    <img
                      src={chevronRight}
                      className={'pointer'}
                      alt={'arrow-right'}
                      onClick={() => {
                        handleNavigateTerms('/terms/marketing');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <BottomButton
              text="가입"
              type={'submit'}
              disabled={
                !personalTermValue ||
                !serviceTermValue ||
                emailVerificationStatus !== EmailVerificationStatus.VERIFIED ||
                !isValid
              }
              isLoading={isLoading}
            />
          </div>
        </div>
      </form>
    </Container>
  );
};

export default RegisterEmail;
