import React from 'react';
import { Link } from 'react-router-dom';
import Icon from './Icon.tsx';
import { BOTTOM_TAB_DATA } from '../util/constants.ts';

interface FooterProps {
  current: string;
  onTabChange?: () => void;
}

const Footer: React.FC<FooterProps> = ({ current, onTabChange }) => {
  return (
    <footer className="footer">
      {BOTTOM_TAB_DATA.map((tab, i) => {
        return (
          <Link
            to={tab.url}
            key={i}
            onClick={() => {
              if (tab.id !== current && onTabChange) {
                onTabChange();
              }
            }}
          >
            <div className="bottom-nav-link">
              <Icon
                iconSrc={current === tab.id ? tab.icon : tab.disabledIcon}
                iconText={tab.urlText}
              />
              <span style={{ whiteSpace: 'nowrap'}}>{tab.urlText}</span>
            </div>
          </Link>
        );
      })}
    </footer>
  );
};

export default Footer;
