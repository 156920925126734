import '@src/scss/main.scss';
import { Link, useNavigate } from 'react-router-dom';
import { HometaxUpdate, IApiError, ICert } from '@src/util/interfaces';
import api from '@src/util/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_URLS, SYNC_MENU, SYNC_MENU_LIST, URLS } from '@src/util/constants';
import { useUserStore } from '@src/util/store';
import React, { useCallback, useState } from 'react';
import Hometax_32 from '@src/assets/icons/logo/hometax_32.svg';
import Financing_32 from '@src/assets/icons/logo/financing_32.svg';
import DetailHeader from '@src/components/DetailHeader';
import Container from '@src/layouts/Container';
import CustomModal from '@src/components/CustomModal';
import TailSpinner from '@src/components/TailSpinner';
import SideBar from '@src/components/SideBar.tsx';
import Modal from '@src/components/Modal';
import CardIcon from '@src/components/CardIcon.tsx';
import DeliveryIcon from '@src/components/DeliveryIcon.tsx';
import CommerceIcon from '@src/components/CommerceIcon.tsx';
import Header from '@src/components/Header.tsx';
import { useIsMobile } from '@src/util/hooks.ts';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';

interface DataSyncProps {}

const Sync: React.FC<DataSyncProps> = () => {
  const navigate = useNavigate();
  const [user] = useUserStore((state) => [state.user]);

  const [idModalOpen, setIdModalOpen] = useState<boolean>(false);
  const [clearModalOpen, setClearModalOpen] = useState<boolean>(false);

  const [financing_id, set_financing_id] = useState<string>('');
  const [financing_password, set_financing_password] = useState<string>('');

  const [key, setKey] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const [error, setError] = useState<string>('');
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(false);
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    SYNC_MENU.SYNC,
  );

  const company_id = user?.selectedCompany?.id;
  const queryClient = useQueryClient();
  const cert = useQuery<ICert>({
    queryKey: [`cert`, company_id],
    queryFn: () => api.get(API_URLS.CERT + `/${user?.selectedCompany?.id}`),
    staleTime: 30 * 60 * 1000,
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
  });

  const idSyncDisable =
    cert?.data?.data.financing_id &&
    cert?.data?.data.financing_password &&
    cert?.data?.data.financing_password_error == false;
  const idPwError = cert?.data?.data.financing_password_error == true;

  const { mutate: updateIdCert, isPending: isLoading } = useMutation({
    mutationFn: (data: HometaxUpdate) =>
      api.post(API_URLS.CERT + `/financing`, { ...data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setIdModalOpen(false);
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const { mutate: updateCertClear } = useMutation({
    mutationFn: (data: { key: string; value: string }) =>
      api.patch(API_URLS.CERT + `/${user?.selectedCompany?.id}`, {
        [data.key]: data.value,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setClearModalOpen(false);
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const handleClear = useCallback(
    (key: string, value: string, title: string) => {
      setKey(key);
      setValue(value);
      setTitle(title);
      setClearModalOpen(true);
    },
    [],
  );

  const handleIdSyncClick = useCallback(() => {
    // if (!financingIdRegex.test(financing_id)) {
    //   setError('6자 이상 20자 이하의 영문 또는 영문+숫자');
    //   return;
    // }
    // if (!financingPasswordRegex.test(financing_password)) {
    //   setError('영문+숫자+특수문자 포함 8~20자');
    //   return;
    // }
    updateIdCert({ financing_id, financing_password, company_id });
  }, [financing_id, financing_password, company_id]);

  if (isLoading) {
    return <TailSpinner type={'full-page'} />;
  }

  if (!user?.role?.data_integration) {
    return (
      <>
        <Modal
          isOpen={true}
          onOk={() => {
            navigate(-1);
          }}
          title={'페이지 권한이 없습니다.'}
          okButton={'확인'}
          onClose={() => {}}
        />
      </>
    );
  }

  return (
    <Container
      header={
        isMobile ? (
          <DetailHeader title={'데이터 연동'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      sideBar={<SideBar />}
      headerTab={
        !isMobile && (
          <HeaderTabs
            menus={SYNC_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
      modal={
        <>
          <CompanyDrawer open={open} setOpen={setOpen} />
          <CustomModal
            onClose={() => {
              setError('');
              setIdModalOpen(false);
            }}
            isOpen={idModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>{`아이디로 연결`}</h2>
                    <div className={'modal-input-wrapper'}>
                      <input
                        className={'modal-input'}
                        value={financing_id}
                        placeholder={'아이디 입력'}
                        onFocus={() => setError('')}
                        onChange={(e) => set_financing_id(e.target.value)}
                      />
                      <input
                        type={'password'}
                        className={'modal-input'}
                        value={financing_password}
                        placeholder={'비밀번호 입력'}
                        onFocus={() => setError('')}
                        onChange={(e) => set_financing_password(e.target.value)}
                      />
                      {error ? (
                        <p className={'error-message'}>{error}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setIdModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() => handleIdSyncClick()}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />

          <CustomModal
            onClose={() => {
              setError('');
              setClearModalOpen(false);
            }}
            isOpen={clearModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2 className={'modal-custom-title'}>{`${title}`}</h2>
                  </div>
                  {value ? (
                    <div className={'modal-input-wrapper'}>
                      <input
                        className={'modal-input'}
                        value={value}
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {error ? <p className={'error-message'}>{error}</p> : <></>}
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-two-button-wrapper'}>
                  <button
                    className={'left-button'}
                    onClick={() => setClearModalOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    className={'right-button'}
                    onClick={() => updateCertClear({ key, value: '' })}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />
        </>
      }
    >
      <div className={'data-sync-wrapper-container'}>
        <div className="data-sync-container">
          <div className={'data-sync-content-wrapper'}>
            <p className={'content-title'}>홈택스</p>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper'}>
                <div className={'hometax-icon'}>
                  <img src={Hometax_32} alt="hometax" />
                </div>
                <p className={'icon-label'}>홈택스</p>
              </div>
              <Link className={'sync-button'} to={URLS.HOMETAX_SYNC}>
                연결하기
              </Link>
            </div>
          </div>
          <div className={'data-sync-content-wrapper'}>
            <p className={'content-title'}>카드매출</p>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper'}>
                <img src={Financing_32} alt="card-sales" />
                <p className={'icon-label'}>
                  여신금융협회
                  {idPwError && (
                    <span className={'error-message'}> (비밀번호 오류)</span>
                  )}
                </p>
              </div>
              {idSyncDisable && (
                <button
                  className={'sync-button-clear'}
                  onClick={() =>
                    handleClear(
                      'financing_id',
                      cert?.data?.data.financing_id || '',
                      '여신금융 아이디 연결해제',
                    )
                  }
                >
                  연결해제
                </button>
              )}
              {!idSyncDisable && (
                <button
                  className={'sync-button'}
                  onClick={() => setIdModalOpen(true)}
                >
                  연결하기
                </button>
              )}
            </div>
          </div>
          <div className={'data-sync-content-wrapper'}>
            <p className={'content-title'}>카드매입</p>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper-gradient'}>
                <CardIcon name={'현대카드'} size={'lg'} />
                <CardIcon name={'비씨카드'} size={'lg'} />
                <CardIcon name={'롯데카드'} size={'lg'} />
                <CardIcon name={'KB카드'} size={'lg'} />
                <CardIcon name={'하나카드'} size={'lg'} />
                <CardIcon name={'삼성카드'} size={'lg'} />
                <CardIcon name={'농협카드'} size={'lg'} />
                <CardIcon name={'신한카드'} size={'lg'} />
                <CardIcon name={'우리카드'} size={'lg'} />
              </div>
              <Link className={'sync-button'} to={URLS.CARD_SYNC}>
                연결하기
              </Link>
            </div>
          </div>
          <div className={'data-sync-content-wrapper'}>
            <p className={'content-title'}>커머스</p>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper-images'}>
                <CommerceIcon name={'NAVER'} size={'lg'} />
                <CommerceIcon name={'COUPANG'} size={'lg'} />
              </div>
              <Link className={'sync-button'} to={URLS.COMMERCE_SYNC}>
                연결하기
              </Link>
            </div>
          </div>
          <div className={'data-sync-content-wrapper'}>
            <p className={'content-title'}>배달앱</p>
            <div className={'data-sync-content'}>
              <div className={'icon-wrapper-images'}>
                <DeliveryIcon name={'BM'} size={'lg'} />
                <DeliveryIcon name={'YO'} size={'lg'} />
                <DeliveryIcon name={'CPE'} size={'lg'} />
              </div>
              <Link className={'sync-button'} to={URLS.DELIVERY_SYNC}>
                연결하기
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Sync;
