import AdminSideBar from '@src/components/AdminSideBar.tsx';
import Container from '@src/layouts/Container.tsx';
import AdminUserContent from '@src/pages/admin/user/AdminUserContent.tsx';

const AdminUser = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <AdminUserContent />
    </Container>
  );
};

export default AdminUser;
