import styled from '@emotion/styled';
import Table from '@src/components/Table.tsx';
import {
  IAdminUser,
  IApiError,
  IApiPOSTSuccess,
} from '@src/util/interfaces.ts';
import {
  adminUserKeywordSearchFilterOption,
  API_URLS_ADMIN,
  COLORS,
} from '@src/util/constants.ts';
import { useAdminUser } from '@src/util/adminHooks.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { z } from 'zod';
import Select from '@src/components/Select.tsx';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { convertToKST } from '@src/util/functions.ts';
import IconSVG from '@src/components/IconSVG.tsx';
import Modal from '@src/components/Modal.tsx';
import Divider from '@src/components/Divider.tsx';
import Button from '@src/components/Button.tsx';
import apiAdmin from '@src/util/apiAdmin.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Snackbar from '@src/components/SnackBar.tsx';

const AdminUserContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 24px;
  margin: 0 48px 48px 48px;
`;

const StyledTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
`;

const KeywordSearchForm = z.object({
  keyword: z.string(),
  keywordSearchFilter: z.object({
    label: z.string(),
    value: z.string(),
  }),
});
const PasswordVerifyForm = z.object({
  password: z.string(),
});
const PasswordVerifyFormServer = PasswordVerifyForm.extend({
  type: z.string(),
});

type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;
type TPasswordVerifyForm = z.infer<typeof PasswordVerifyForm>;
type TPasswordVerifyFormServer = z.infer<typeof PasswordVerifyFormServer>;

const AdminUserContent = () => {
  const [keyword, setKeyword] = useState<string>('');

  const [keywordSearchCategory, setKeywordSearchCategory] = useState<
    'name' | 'email' | 'phone_number'
  >('name');
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
  const [deleteUserNo, setDeleteUserNo] = useState<number | null>(null);
  const [verifyPasswordModal, setVerifyPasswordModal] =
    useState<boolean>(false);

  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');

  const { data, fetchNextPage, hasNextPage } = useAdminUser(
    keyword,
    keywordSearchCategory,
  );
  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '성함', value: '성함' },
    },
  });
  const queryClient = useQueryClient();

  const {
    register: registerVerifyPassword,
    handleSubmit: handleSubmitVerifyPassword,
    reset: resetForm,
  } = useForm<TPasswordVerifyForm>({
    resolver: zodResolver(PasswordVerifyForm),
  });

  const {
    mutate,
    isPending: passwordVerifyIsLoading,
    error,
    reset: resetMutation,
  } = useMutation<IApiPOSTSuccess, IApiError, TPasswordVerifyFormServer>({
    mutationFn: (formData) => {
      return apiAdmin.post(API_URLS_ADMIN.CHECK_ADMIN_PASSWORD, formData);
    },
  });

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '성함') {
      setKeywordSearchCategory('name');
    } else if (keywordSearchCategoryValue === '이메일') {
      setKeywordSearchCategory('email');
    } else if (keywordSearchCategoryValue === '전화번호') {
      setKeywordSearchCategory('phone_number');
    }

    setKeyword(data.keyword);
  };

  const onSubmitVerifyPassword: SubmitHandler<TPasswordVerifyForm> = (data) => {
    const formData = {
      type: 'ADMIN',
      password: data.password,
    };
    mutate(formData, {
      onSuccess: (response) => {
        console.log(response);
        setVerifyPasswordModal(false);
        apiAdmin
          .delete(API_URLS_ADMIN.USERS + `/${deleteUserNo}`)
          .then((res) => {
            console.log(res);
            setSnackBarContent(
              `${flattenedData?.filter((item) => item.id === deleteUserNo)[0]
                .name}가 삭제되었습니다.`,
            );
            setSnackBarOpen(true);
            setTimeout(() => {
              setSnackBarOpen(false);
            }, 3000);
            resetForm();
            setDeleteUserNo(null);

            queryClient.invalidateQueries({ queryKey: ['admin_user'] });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const flattenedData = data.pages.flatMap((page) => page.data);
  const columnHelper = createColumnHelper<IAdminUser>();
  const columns: Array<ColumnDef<IAdminUser, string>> = [
    columnHelper.accessor('name', {
      id: 'name',
      cell: (props) => {
        const color =
          props.row.original.type === 'A'
            ? COLORS['green']
            : props.row.original.type === 'B'
              ? COLORS['primary']
              : COLORS['main'];

        return <i style={{ color: color }}>{props.getValue()}</i>;
      },
      header: '성함',
    }),
    columnHelper.accessor('email', {
      id: 'email',
      cell: (info) => <i>{info.getValue()}</i>,
      header: '이메일',
    }),
    columnHelper.accessor('phone_number', {
      id: 'phone_number',
      cell: (info) => (
        <i>{info.getValue().trim() === '' ? '-' : info.getValue()}</i>
      ),
      header: '연락처',
    }),
    columnHelper.accessor('companies', {
      id: 'companies',
      cell: (info) => <i>{info.getValue()}</i>,
      header: '회사수',
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: (info) => {
        return <i>{convertToKST(info.getValue())}</i>;
      },
      header: '가입일',
    }),
    columnHelper.display({
      id: 'delete',
      cell: (props) => {
        if (parseInt(props.row.original.companies) === 0) {
          return (
            <div className={'flex-row-center'}>
              <IconSVG
                fill={COLORS['red']}
                size={'24'}
                iconSVG={'delete'}
                containerStyle={{ cursor: 'pointer' }}
                onClick={() => {
                  setConfirmDeleteModal(true);
                  setDeleteUserNo(props.row.original.id);
                }}
              />
            </div>
          );
        } else {
          return <></>;
        }
      },
      header: () => <div style={{ minWidth: '28px' }}>삭제</div>,
    }),
  ];

  return (
    <>
      <AdminUserContentContainer>
        <StyledTitle>유저 리스트</StyledTitle>
        <Table<IAdminUser>
          data={flattenedData}
          columns={columns}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          getRowStyle={(row) => ({
            backgroundColor:
              row.original.companies == '0' ? COLORS['gray2'] : '',
          })}
          filterContent={
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={'table-filter-container'}
                style={{ justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={'table-filter-keyword-search-container'}>
                    <div style={{ width: '128px' }}>
                      <Controller
                        name="keywordSearchFilter"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              name="keywordSearchFilter"
                              options={adminUserKeywordSearchFilterOption}
                              onChange={onChange}
                              value={value}
                              isFilterSearch={true}
                            />
                          );
                        }}
                      />
                    </div>

                    <Input
                      placeholder={'검색어 입력'}
                      register={register('keyword')}
                      style={{
                        height: 'auto',
                        borderRadius: '0',
                        borderLeft: 'none',
                        width: '414px',
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent the default behavior of the enter key
                          handleSubmit(onSubmit)(); // Execute the submit handler
                        }
                      }}
                    />
                    <Icon
                      iconSrc={searchIcon}
                      style={{
                        padding: '10px',
                        border: `1px solid ${COLORS['gray2']}`,
                        borderLeft: 'none',
                        borderBottomRightRadius: '4px',
                        borderTopRightRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '4px',
                        background: COLORS['main'],
                      }}
                    ></div>
                    <p className={'text-sm'}>카카오</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '4px',
                        background: COLORS['primary'],
                      }}
                    ></div>
                    <p className={'text-sm'}>이메일</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '4px',
                        background: COLORS['green'],
                      }}
                    ></div>
                    <p className={'text-sm'}>카카오&이메일</p>
                  </div>
                </div>
              </div>
            </form>
          }
        />
      </AdminUserContentContainer>
      <Snackbar message={snackBarContent} open={snackBarOpen} type={'info'} />
      <Modal
        isOpen={confirmDeleteModal}
        onOk={() => {
          setConfirmDeleteModal(false);
          setVerifyPasswordModal(true);
        }}
        title={'유저 삭제'}
        content={'유저정보를 삭제하시겠습니까?'}
        okButton={'삭제'}
        cancelButton={'취소'}
        onCancel={() => {
          setConfirmDeleteModal(false);
          setDeleteUserNo(null);
        }}
        onClose={() => {
          setConfirmDeleteModal(false);
          setDeleteUserNo(null);
        }}
      />
      <Modal
        onClose={() => {
          setVerifyPasswordModal(false);
          setDeleteUserNo(null);
        }}
        isOpen={verifyPasswordModal}
        body={
          <form onSubmit={handleSubmitVerifyPassword(onSubmitVerifyPassword)}>
            <div
              className={'modal-custom-wrapper'}
              style={{ paddingBottom: 0 }}
            >
              <div className={'modal-custom-content-wrapper'}>
                <h2 className={'modal-custom-title'}>비밀번호 확인</h2>
              </div>
              <div className={'modal-input-wrapper'}>
                <Input
                  register={registerVerifyPassword('password')}
                  type={'password'}
                  placeholder={'비밀번호 입력'}
                />
                {error && (
                  <div className="error-message">
                    {error?.response?.data?.message}
                  </div>
                )}
              </div>
            </div>
            <Divider
              customClassName="overflow-divider-modal"
              style={{ marginTop: '20px' }}
            />
            <div className={'custom-modal-two-button-wrapper'}>
              <Button
                customClassName={'left-button'}
                text={'취소'}
                type={'button'}
                onClick={() => {
                  resetForm();
                  resetMutation();
                  setVerifyPasswordModal(false);
                  setDeleteUserNo(null);
                }}
              />
              <Button
                customClassName={'right-button'}
                type={'submit'}
                text={'확인'}
                isLoading={passwordVerifyIsLoading}
              />
            </div>
          </form>
        }
      />
    </>
  );
};

export default AdminUserContent;
