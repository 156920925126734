import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
// import { ReactQueryDevtools } from "react-query/devtools";
import Login from './pages/auth/Login.tsx';
import EmailLogin from './pages/auth/EmailLogin.tsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RegisterEmail from './pages/auth/RegisterEmail.tsx';
import ProtectedRoute from './routes/ProtectedRoute.tsx';
import RegisterCompany from './pages/auth/RegisterCompany.tsx';
import CertificatesForm from './pages/certificates/CertificatesForm.tsx';
import FindId from './pages/auth/FindId';
import { URLS } from './util/constants';
import ReportAdditional from './pages/reports/ReportAdditional.tsx';
import FindIdComplete from './pages/auth/FindIdComplete';
import FindPw from './pages/auth/FindPw';
import Labor from './pages/labor/Labor.tsx';
import MyPage from './pages/my-page/MyPage';
import AddManager from './pages/my-page/AddManager';
import UpdateManager from './pages/my-page/UpdateManager';
import ChangePw from './pages/my-page/ChangePw';
import Menu from './pages/menu/Menu.tsx';
import ExpectedTax from './pages/expected-tax/ExpectedTax';
import Sync from './pages/sync/sync';
import HometaxSync from './pages/sync/hometax-sync';
import CommerceSync from './pages/sync/commerce-sync';
import DeliverySync from './pages/sync/delivery-sync';
import CardSync from './pages/sync/card-sync';
import TaskRequest from './pages/tax/TaskRequest.tsx';
import TaskDetail from './pages/tax/TaskDetail.tsx';
import BasicQuestion3 from './pages/labor/BasicQuestion3';
import BasicQuestion1 from './pages/labor/BasicQuestion1';
import BasicQuestion2 from './pages/labor/BasicQuestion2';
import StopQuestion from './pages/labor/StopQuestion';
import PossibleQuestion from './pages/labor/PossibleQuestion';
import EndQuestion from './pages/labor/EndQuestion';
import YouthQuestion1 from './pages/labor/YouthQuestion1';
import YouthQuestion2 from './pages/labor/YouthQuestion2';
import YouthQuestion3 from './pages/labor/YouthQuestion3';
import YouthQuestion4 from './pages/labor/YouthQuestion4';
import YouthQuestion5 from './pages/labor/YouthQuestion5';
import YouthQuestion6 from './pages/labor/YouthQuestion6';
import YouthQuestion7 from './pages/labor/YouthQuestion7';
import SeniorQuestion1 from './pages/labor/SeniorQuestion1';
import SeniorQuestion8 from './pages/labor/SeniorQuestion8';
import SeniorQuestion7 from './pages/labor/SeniorQuestion7';
import SeniorQuestion6 from './pages/labor/SeniorQuestion6';
import SeniorQuestion5 from './pages/labor/SeniorQuestion5';
import SeniorQuestion4 from './pages/labor/SeniorQuestion4';
import SeniorQuestion3 from './pages/labor/SeniorQuestion3';
import SeniorQuestion2 from './pages/labor/SeniorQuestion2';
import BabyQuestion1 from './pages/labor/BabyQuestion1';
import BabyQuestion2 from './pages/labor/BabyQuestion2';
import BabyQuestion3 from './pages/labor/BabyQuestion3';
import BabyQuestion4 from './pages/labor/BabyQuestion4';
import BabyQuestion5 from './pages/labor/BabyQuestion5';
import RegisterQuestion from './pages/labor/RegisterQuestion';
import ReceiptDetail from './pages/tax/ReceiptDetail.tsx';
import ExpectedPaymentDetail from './pages/reports/ExpectedPaymentDetail.tsx';
import AdminRoute from './routes/AdminRoute.tsx';
import Client from './pages/admin/client/Client.tsx';
import NotFound from '@src/pages/util/NotFound.tsx';
import AdminEmailLogin from '@src/pages/admin/auth/AdminEmailLogin.tsx';
import CertificatesAdmin from '@src/pages/admin/certificates/CertificatesAdmin.tsx';
import CertificatesDetailAdmin from '@src/pages/admin/certificates/CertificatesDetailAdmin.tsx';
import LaborAdmin from '@src/pages/admin/labor/LaborAdmin.tsx';
import TaskAdmin from '@src/pages/admin/task/TaskAdmin.tsx';
import TaskDetailAdmin from '@src/pages/admin/task/TaskDetailAdmin.tsx';
import ReceiptAdmin from '@src/pages/admin/receipt/ReceiptAdmin.tsx';
import ReceiptDetailAdmin from '@src/pages/admin/receipt/ReceiptDetailAdmin.tsx';
import ExpectedTaxAdmin from '@src/pages/admin/expected-tax/ExpectedTaxAdmin.tsx';
import MyPageAdmin from '@src/pages/admin/my-page/MyPageAdmin.tsx';
import BranchAdmin from '@src/pages/admin/branch/BranchAdmin.tsx';
import PersonalTerms from '@src/pages/terms/PersonalTerms.tsx';
import ServiceTerms from '@src/pages/terms/ServiceTerms.tsx';
import MarketingTerms from '@src/pages/terms/MarketingTerms.tsx';
import InProgress from '@src/pages/util/InProgress.tsx';
import ReportsAdmin from '@src/pages/admin/reports/ReportsAdmin.tsx';
import CertificateRequest from '@src/pages/certificates/CertificateRequest.tsx';
import CertificateIssued from '@src/pages/certificates/CertificateIssued.tsx';
import Task from '@src/pages/tax/Task.tsx';
import TaxPaid from '@src/pages/tax/TaxPaid.tsx';
import Receipt from '@src/pages/tax/Receipt.tsx';
import ReportMonthly from '@src/pages/reports/ReportMonthly.tsx';
import ReportDaily from '@src/pages/reports/ReportDaily.tsx';
import ReportYearly from '@src/pages/reports/ReportYearly.tsx';
import ExpectedPayment from '@src/pages/reports/ExpectedPayment.tsx';
import ErrorsAdmin from '@src/pages/admin/errors/ErrorsAdmin.tsx';
import Batch from '@src/pages/admin/batch/Batch';
import TaxInvoice from '@src/pages/tax-invoice/TaxInvoice.tsx';
import AddressSearch from '@src/pages/address/AddressSearch.tsx';
import IndustrySearch from '@src/pages/industry/IndustrySearch.tsx';
import CustomerSearch from '@src/pages/customer/CustomerSearch.tsx';
import AdminSync from '@src/pages/admin/sync/AdminSync.tsx';
import AdminUser from '@src/pages/admin/user/AdminUser.tsx';

const queryClient = new QueryClient();

interface Auth {
  authorize(object: object): string;
}

interface Kakao {
  init(key: string): void;

  isInitialized(): boolean;

  Auth: Auth;
}

declare global {
  interface Window {
    Kakao: Kakao;
  }
}
const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/reports/monthly" replace />,
  },
  {
    path: '/admin',
    element: <Navigate to="/admin/client" replace />,
  },
  {
    path: '/reports',
    element: <Navigate to="/reports/monthly" replace />,
  },
  {
    path: '/reports/monthly',
    element: (
      <ProtectedRoute>
        <ReportMonthly />
      </ProtectedRoute>
    ),
  },
  {
    path: '/reports/daily',
    element: (
      <ProtectedRoute>
        <ReportDaily />
      </ProtectedRoute>
    ),
  },
  {
    path: '/reports/yearly',
    element: (
      <ProtectedRoute>
        <ReportYearly />
      </ProtectedRoute>
    ),
  },
  {
    path: '/reports/expected-payment',
    element: (
      <ProtectedRoute>
        <ExpectedPayment />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/client',
    element: (
      <AdminRoute>
        <Client />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/sync',
    element: (
      <AdminRoute>
        <AdminSync />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/user',
    element: (
      <AdminRoute>
        <AdminUser />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/reports',
    element: (
      <AdminRoute>
        <ReportsAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/certificates',
    element: (
      <AdminRoute>
        <CertificatesAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/certificates/:id',
    element: (
      <AdminRoute>
        <CertificatesDetailAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/labor',
    element: (
      <AdminRoute>
        <LaborAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/task',
    element: (
      <AdminRoute>
        <TaskAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/task/:id',
    element: (
      <AdminRoute>
        <TaskDetailAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/receipt',
    element: (
      <AdminRoute>
        <ReceiptAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/receipt/:id',
    element: (
      <AdminRoute>
        <ReceiptDetailAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/expected-tax',
    element: (
      <AdminRoute>
        <ExpectedTaxAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/my-page',
    element: (
      <AdminRoute>
        <MyPageAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/branch',
    element: (
      <AdminRoute>
        <BranchAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/errors',
    element: (
      <AdminRoute>
        <ErrorsAdmin />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/batch',
    element: (
      <AdminRoute>
        <Batch />
      </AdminRoute>
    ),
  },
  {
    path: '/admin/login',
    element: <AdminEmailLogin />,
  },
  {
    path: '/qna',
    element: <InProgress />,
  },
  {
    path: '/admin/qna',
    element: <InProgress />,
  },
  // {
  //   path: "/admin/register",
  //   element: <AdminLogin />,
  // },
  {
    path: '/expected-payment',
    element: (
      <ProtectedRoute>
        <ExpectedPaymentDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/reports/additional/:term/:category',
    element: <ReportAdditional />,
  },
  {
    path: '/certificates',
    element: <Navigate to="/certificates/request" replace />,
  },
  {
    path: '/certificates/request',
    element: (
      <ProtectedRoute>
        <CertificateRequest />
      </ProtectedRoute>
    ),
  },
  {
    path: '/certificates/issued',
    element: (
      <ProtectedRoute>
        <CertificateIssued />
      </ProtectedRoute>
    ),
  },
  {
    path: '/certificates/form/:type',
    element: (
      <ProtectedRoute>
        <CertificatesForm />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.LABOR,
    element: (
      <ProtectedRoute>
        <Labor />
      </ProtectedRoute>
    ),
  },
  {
    path: '/labor/request',
    element: <Navigate to="/labor" replace />,
  },
  {
    path: `${URLS.LABOR}/step/1`,
    element: (
      <ProtectedRoute>
        <Labor />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/question/1`,
    element: (
      <ProtectedRoute>
        <BasicQuestion1 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/question/2`,
    element: (
      <ProtectedRoute>
        <BasicQuestion2 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/question/3`,
    element: (
      <ProtectedRoute>
        <BasicQuestion3 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/stop`,
    element: (
      <ProtectedRoute>
        <StopQuestion />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/possible`,
    element: (
      <ProtectedRoute>
        <PossibleQuestion />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/end`,
    element: (
      <ProtectedRoute>
        <EndQuestion />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/register`,
    element: (
      <ProtectedRoute>
        <RegisterQuestion />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/youth/1`,
    element: (
      <ProtectedRoute>
        <YouthQuestion1 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/youth/2`,
    element: (
      <ProtectedRoute>
        <YouthQuestion2 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/youth/3`,
    element: (
      <ProtectedRoute>
        <YouthQuestion3 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/youth/4`,
    element: (
      <ProtectedRoute>
        <YouthQuestion4 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/youth/5`,
    element: (
      <ProtectedRoute>
        <YouthQuestion5 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/youth/6`,
    element: (
      <ProtectedRoute>
        <YouthQuestion6 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/youth/7`,
    element: (
      <ProtectedRoute>
        <YouthQuestion7 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/senior/1`,
    element: (
      <ProtectedRoute>
        <SeniorQuestion1 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/senior/2`,
    element: (
      <ProtectedRoute>
        <SeniorQuestion2 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/senior/3`,
    element: (
      <ProtectedRoute>
        <SeniorQuestion3 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/senior/4`,
    element: (
      <ProtectedRoute>
        <SeniorQuestion4 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/senior/5`,
    element: (
      <ProtectedRoute>
        <SeniorQuestion5 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/senior/6`,
    element: (
      <ProtectedRoute>
        <SeniorQuestion6 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/senior/7`,
    element: (
      <ProtectedRoute>
        <SeniorQuestion7 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/senior/8`,
    element: (
      <ProtectedRoute>
        <SeniorQuestion8 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/baby/1`,
    element: (
      <ProtectedRoute>
        <BabyQuestion1 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/baby/2`,
    element: (
      <ProtectedRoute>
        <BabyQuestion2 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/baby/3`,
    element: (
      <ProtectedRoute>
        <BabyQuestion3 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/baby/4`,
    element: (
      <ProtectedRoute>
        <BabyQuestion4 />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.LABOR}/baby/5`,
    element: (
      <ProtectedRoute>
        <BabyQuestion5 />
      </ProtectedRoute>
    ),
  },
  {
    path: '/tax',
    element: <Navigate to="/tax/task" replace />,
  },
  {
    path: '/tax/task',
    element: (
      <ProtectedRoute>
        <Task />
      </ProtectedRoute>
    ),
  },
  {
    path: '/tax/tax_paid',
    element: (
      <ProtectedRoute>
        <TaxPaid />
      </ProtectedRoute>
    ),
  },
  {
    path: '/tax/receipt',
    element: (
      <ProtectedRoute>
        <Receipt />
      </ProtectedRoute>
    ),
  },
  {
    path: '/tax/task/request',
    element: (
      <ProtectedRoute>
        <TaskRequest />
      </ProtectedRoute>
    ),
  },
  {
    path: '/tax/task/:id',
    element: (
      <ProtectedRoute>
        <TaskDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/tax/receipt/:id',
    element: (
      <ProtectedRoute>
        <ReceiptDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/menu',
    element: (
      <ProtectedRoute>
        <Menu />
      </ProtectedRoute>
    ),
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/login/email',
    element: <EmailLogin />,
  },
  {
    path: URLS.FIND_ID,
    element: <FindId />,
  },
  {
    path: URLS.FIND_ID_COMPLETE,
    element: <FindIdComplete />,
  },
  {
    path: URLS.FIND_PW,
    element: <FindPw />,
  },
  {
    path: '/register/email',
    element: <RegisterEmail />,
  },
  {
    path: '/terms/personal',
    element: <PersonalTerms />,
  },
  {
    path: '/terms/service',
    element: <ServiceTerms />,
  },
  {
    path: '/terms/marketing',
    element: <MarketingTerms />,
  },
  {
    path: '/register/company',
    element: (
      // <ProtectedRoute>
      <RegisterCompany />
      // </ProtectedRoute>
    ),
  },
  {
    path: URLS.MY_PAGE,
    element: (
      <ProtectedRoute>
        <MyPage />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.ADD_MANAGER,
    element: (
      <ProtectedRoute>
        <AddManager />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.UPDATE_MANAGER,
    element: (
      <ProtectedRoute>
        <UpdateManager />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.CHANGE_PW,
    element: (
      <ProtectedRoute>
        <ChangePw />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.SYNC,
    element: (
      <ProtectedRoute>
        <Sync />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.HOMETAX_SYNC,
    element: (
      <ProtectedRoute>
        <HometaxSync />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.COMMERCE_SYNC,
    element: (
      <ProtectedRoute>
        <CommerceSync />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.DELIVERY_SYNC,
    element: (
      <ProtectedRoute>
        <DeliverySync />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.CARD_SYNC,
    element: (
      <ProtectedRoute>
        <CardSync />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.EXPECTED_TAX,
    element: (
      <ProtectedRoute>
        <ExpectedTax />
      </ProtectedRoute>
    ),
  },
  {
    path: `${URLS.EXPECTED_TAX}/법인세`,
    element: <Navigate to={URLS.EXPECTED_TAX} replace />,
  },
  {
    path: URLS.TAX_INVOICE,
    element: <Navigate to={URLS.TAX_INVOICE_REGULAR} replace />,
  },
  {
    path: URLS.TAX_INVOICE_REGULAR,
    element: (
      <ProtectedRoute>
        <TaxInvoice />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.TAX_INVOICE_EXEMPT,
    element: (
      <ProtectedRoute>
        <TaxInvoice />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.TAX_INVOICE_ISSUED,
    element: (
      <ProtectedRoute>
        <TaxInvoice />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.ADDRESS_SEARCH,
    element: (
      <ProtectedRoute>
        <AddressSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.INDUSTRY_SEARCH,
    element: (
      <ProtectedRoute>
        <IndustrySearch />
      </ProtectedRoute>
    ),
  },
  {
    path: URLS.CUSTOMER_SEARCH,
    element: (
      <ProtectedRoute>
        <CustomerSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
    {/*<ReactQueryDevtools initialIsOpen={true} />*/}
  </QueryClientProvider>,
);
