import React, { useCallback, useEffect, useState } from 'react';
import Container from '@src/layouts/Container';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import { LABOR_MENU, LABOR_MENU_LIST, URLS } from '@src/util/constants';
import laborImg from '/src/assets/images/labor-request.svg';
import { createQuestionStore, useUserStore } from '@src/util/store';
import { useNavigate } from 'react-router-dom';
import SideBar from '@src/components/SideBar';
import Modal from '@src/components/Modal';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import { refreshToken } from '@src/util/api.ts';
import DetailHeader from '@src/components/DetailHeader.tsx';
import Header from '@src/components/Header.tsx';
import { useIsMobile } from '@src/util/hooks.ts';

interface LaborProps {}

const Labor: React.FC<LaborProps> = () => {
  const navigate = useNavigate();
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    LABOR_MENU.REQUEST,
  );

  const [user] = useUserStore((state) => [state.user]);
  const isMobile = useIsMobile();
  const company_id = user?.selectedCompany?.id || 0;
  const [question, setQuestion] = createQuestionStore(company_id)((state) => [
    state.question,
    state.setQuestion,
  ]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    refreshToken();
  }, []);

  const handleRefreshClick = useCallback(() => {
    setQuestion({
      youth_amount: undefined,
      senior_amount: undefined,
      baby_amount: undefined,
      current_page: undefined,
    });
    navigate(`${URLS.LABOR}/question/1`);
  }, []);

  const handleClick = useCallback(() => {
    navigate(question?.current_page || `${URLS.LABOR}/question/1`);
  }, []);

  if (!user?.role?.labor_subsidy) {
    return (
      <>
        <Modal
          isOpen={true}
          onOk={() => {
            navigate(-1);
          }}
          title={'페이지 권한이 없습니다.'}
          okButton={'확인'}
          onClose={() => {}}
        />
      </>
    );
  }

  return (
    <Container
      header={
        isMobile ? (
          <DetailHeader title={'노무지원금 신청'} />
        ) : (
          <Header setOpen={setOpen} />
        )
      }
      headerTab={
        <HeaderTabs
          menus={LABOR_MENU_LIST}
          activeHeaderTab={activeHeaderTab}
          setActiveHeaderTab={(value) => {
            setActiveHeaderTab(value);
          }}
        />
      }
      sideBar={<SideBar />}
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
    >
      <div className={'labor-request-wrapper-container'}>
        <div className={'labor-request-container'}>
          <div className="labor-request-description-container">
            <p className="text-md">
              정부에서 제공하는 다양한
              <br />
              노무지원금을 간편하게
              <br />
              확인해보세요!
            </p>
            <p className="text-regular" style={{ color: '#949494' }}>
              확인 후 노무법인을 통해 상담-신청-접수까지 가능합니다.
            </p>
          </div>
          <div className="flex-row-center-center my-1 pb-70">
            <img src={laborImg} alt={'labor'} />
          </div>

          <div className="labor-request-bottom-button">
            <button
              className={'labor-request-left-button'}
              onClick={handleRefreshClick}
            >
              확인하기
            </button>
            <button
              className={'labor-request-right-button'}
              onClick={handleClick}
            >
              이어서하기
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Labor;
