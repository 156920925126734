import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../util/constants';
import menuIcon from '/src/assets/icons/bottom/menu.svg';
import IconSVG from './IconSVG.tsx';
import { useAdminSidebarStore, useAdminStore } from '@src/util/adminStore.ts';
import logoIcon from '/src/assets/icons/logo-final.png';
import { useQueryClient } from '@tanstack/react-query';
import ReportIcon from '@src/assets/icons/ReportIcon.tsx';

interface AdminSideBarProps {}

const AdminSideBar: React.FC<AdminSideBarProps> = () => {
  const { collapsed, toggleCollapsed } = useAdminSidebarStore();
  const [admin] = useAdminStore((state) => [state.admin]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return (
    <Sidebar
      collapsed={collapsed}
      className={'admin-side-bar-container'}
      backgroundColor={'white'}
      style={{ position: 'fixed', top: 0, left: 0, zIndex: '5001' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          cursor: 'pointer',
        }}
      >
        <button
          className={'side-bar-header'}
          onClick={toggleCollapsed}
          type={'button'}
        >
          <img className={'icon'} src={menuIcon} alt="메뉴" />
          <img
            src={logoIcon}
            alt="logo"
            style={{ marginLeft: '5px', maxWidth: '100px' }}
          />
        </button>
        <Menu style={{ fontSize: '16px', fontWeight: '600' }}>
          <MenuItem
            icon={
              <IconSVG fill={COLORS['main']} size={'24'} iconSVG={'building'} />
            }
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['client'] });
              navigate('/admin/client');
            }}
          >
            고객 리스트
          </MenuItem>
          <MenuItem
            icon={
              <IconSVG fill={COLORS['main']} size={'24'} iconSVG={'sync'} />
            }
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['client'] });
              navigate('/admin/sync');
            }}
          >
            데이터 연동 관리
          </MenuItem>
          <MenuItem
            icon={<ReportIcon fill={COLORS['main']} size={'24'} />}
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['client'] });
              navigate('/admin/reports');
            }}
          >
            리포트 관리
          </MenuItem>
          <MenuItem
            icon={
              <IconSVG fill={COLORS['main']} size={'24'} iconSVG={'print'} />
            }
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['certificates'] });
              navigate('/admin/certificates');
            }}
          >
            증명서발급 관리
          </MenuItem>
          <MenuItem
            icon={
              <IconSVG fill={COLORS['main']} size={'24'} iconSVG={'money'} />
            }
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['laborAdmin'] });
              navigate('/admin/labor');
            }}
          >
            노무지원금 관리
          </MenuItem>
          <MenuItem
            icon={
              <IconSVG
                fill={COLORS['main']}
                size={'24'}
                iconSVG={'checkList'}
              />
            }
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['taskAdmin'] });
              navigate('/admin/task');
            }}
          >
            업무처리 현황 관리
          </MenuItem>
          <MenuItem
            icon={
              <IconSVG fill={COLORS['main']} size={'24'} iconSVG={'contract'} />
            }
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['receiptAdmin'] });
              navigate('/admin/receipt');
            }}
          >
            영수증 등록 관리
          </MenuItem>
          <MenuItem
            icon={
              <IconSVG
                fill={COLORS['main']}
                size={'24'}
                iconSVG={'calculator'}
              />
            }
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['expectedTaxAdmin'] });
              navigate('/admin/expected-tax');
            }}
          >
            예상세금 관리
          </MenuItem>
          <MenuItem
            icon={
              <IconSVG fill={COLORS['main']} size={'24'} iconSVG={'account'} />
            }
            onClick={() => navigate('/admin/my-page')}
          >
            마이 페이지
          </MenuItem>
          <MenuItem
            icon={
              <IconSVG fill={COLORS['main']} size={'24'} iconSVG={'userList'} />
            }
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['admin_user'] });
              navigate('/admin/user');
            }}
          >
            유저 리스트
          </MenuItem>

          <MenuItem
            icon={
              <IconSVG fill={COLORS['main']} size={'24'} iconSVG={'building'} />
            }
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['branch_admin'] });
              navigate('/admin/branch');
            }}
          >
            {admin?.branch === '본점' ? '지점/담당자 관리' : '담당자 관리'}
          </MenuItem>

          {admin?.branch === '본점' && (
            <MenuItem
              icon={
                <IconSVG
                  fill={COLORS['main']}
                  size={'24'}
                  iconSVG={'lightbulb'}
                />
              }
              onClick={() => navigate('/admin/errors')}
            >
              에러 내역
            </MenuItem>
          )}
          {admin?.branch === '본점' && (
            <MenuItem
              icon={
                <IconSVG
                  fill={COLORS['main']}
                  size={'24'}
                  iconSVG={'building'}
                />
              }
              onClick={() => {
                queryClient.invalidateQueries({ queryKey: ['branch_admin'] });
                navigate('/admin/batch');
              }}
            >
              배치 관리
            </MenuItem>
          )}
        </Menu>
      </div>
    </Sidebar>
  );
};

export default AdminSideBar;
